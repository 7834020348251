<template>
    <base-page>
      <el-card style="margin: 5px 0px 5px 0px">
        <el-collapse accordion style="border: none">
          <el-collapse-item name="1" style="border: none">
            <template slot="title">
              <div slot="header" class="clearfix">
                <titlu-pagina :Titlu="'Facturi EFactura: ' + PaginationInfo.RowCount + ' facturi'" :AdaugaVisible="false" />
              </div>
            </template>
            <div class="filtre">
              <p style="color: white">
                TOTAL VALOARE: {{ TotalValoare | format_money }} LEI
              </p>
              <el-form @submit.prevent.native="refresh_info()" label-position="top">
                <el-row :gutter="20">
                  
                  <el-col :md="4">
                    <el-form-item label="Data" >
                      <el-date-picker size="mini" @change="refresh_info()" v-model="Filters.DataFactura" type="date" value-format="yyyy-MM-dd"
                        format="dd.MM.yyyy" />
                    </el-form-item>
                  </el-col>
                  <el-col :md="4">
                    <el-form-item label="Nume client"  >
                      <el-input size="mini" v-model="Filters.NumeClient" />
                    </el-form-item>
                  </el-col>
                  <el-col :md="4">
                    <el-form-item label="Status e-factura" >
                      <el-select v-model="Filters.StatusEfactura" @change="refresh_info()" size="mini" >
                        <el-option v-for="item in Info.StatusuriEfactura" :key="item.value" :label="item.label" :value="item.value"/>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col style="width: 100%">
                    <div class="footer-form">
                      <el-button size="mini" type="primary" native-type="submit" @click="refresh_info()">
                        Aplica
                      </el-button>
                      <el-button size="mini" type="danger" @click="reseteaza()">
                        Reseteaza
                      </el-button>
                      <el-button v-if="Filters.DataFactura !=''" size="mini" type="warning" @click="trimite_bulk()">
                        Trimite facturi bulk
                      </el-button>
                      
  
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-card>

      
  
      <el-table :header-cell-style="{ background: '#393E46' }" :data="Results" class="tabelRandMic">
        <el-table-column type="expand">
          <template slot-scope="props">
            <div style="padding-left:50px; max-width: 1200px;">
              <el-table :data="props.row.Randuri">
                <el-table-column prop="Den" label="Denumire" />
                <el-table-column prop="Cantitate"   label="Cantitate" width="100" align="center"/>
                
                <el-table-column prop="PretUnitarFaraTva"  label="P.U fara TVA"      width="100"  align="right">
                  <template slot-scope="scope">
                    {{ scope.row.PretUnitarFaraTva | format_money }} Lei
                  </template>
                </el-table-column>

                <el-table-column prop="PretUnitarFaraTvaDiscountat"  label="P.U fara TVA discountat"      width="100"  align="right">
                  <template slot-scope="scope">
                    {{ scope.row.PretUnitarFaraTvaDiscountat | format_money }} Lei
                  </template>
                </el-table-column>

                <el-table-column prop="PretUnitarCuTva"  label="P.U cu TVA"      width="140"  align="right">
                  <template slot-scope="scope">
                    {{ scope.row.PretUnitarCuTva | format_money }} Lei
                  </template>
                </el-table-column>

                <el-table-column prop="Valoare"     label="Valoare fara TVA"   width="150" align="right">
                  <template slot-scope="scope">
                    {{ scope.row.ValoareFaraTva | format_money }} Lei
                  </template>
                </el-table-column>

                <el-table-column prop="Valoare"     label="Valoare cu TVA"   width="150" align="right">
                  <template slot-scope="scope">
                    {{ scope.row.ValoareCuTva | format_money }} Lei
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="Id" label="Id" width="60" /> -->
        <el-table-column prop="NumarFactura" label="Nr. Fact" width="80" />
        <el-table-column prop="NumeClient" label="Client" min-width="350" >
          <template slot-scope="scope">
            <span style="text-decoration: underline; cursor: pointer" @click="show_customer_dialog(scope.row.IdCustomer)">
              {{ scope.row.NumeClient }} 
            </span>
            <span v-if="scope.row.PersoanaJuridica" style="font-size:8px; font-weight: bold; color: rgb(236, 245, 255)">CF: {{ scope.row.CF }} </span><br/>
            
            <div v-if="scope.row.PersoanaJuridica">
              <el-tag v-if="scope.row.StatusEfactura=='none'"       size="mini" type="">De trimis</el-tag>
              <el-tag v-if="scope.row.StatusEfactura=='pending'"    size="mini" type="info">In asteptare</el-tag>
              <el-tag v-if="scope.row.StatusEfactura=='uploaded'"   size="mini" type="info">In procesare</el-tag>
              
              <div v-if="scope.row.StatusEfactura=='error'"  >
                <el-tooltip :content="scope.row.EroareEfactura" >
                  <el-tag size="mini" type="danger">Eroare</el-tag>
                </el-tooltip>
              </div>

              <el-tooltip  v-if="scope.row.StatusEfactura=='validated'" content="Click pentru download">
                <a :href="base_url + '/../assets/e-factura/arhiva/' + scope.row.FisierZipRaspuns" target="_blank">
                  <el-tag size="mini" type="success">Validata</el-tag>
                </a>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="IdJudet" label="Judet" width="200">
          <template slot-scope="scope">
            <el-select class="full-width" v-model="scope.row.IdJudet" size="mini" filterable>
              <el-option v-for="item in Info.Judete" :key="'judet-'+item.Id" :label="item.Nume" :value="item.Id"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="Oras" label="Oras" width="200">
          <template slot-scope="scope">
            
            <el-input v-if="scope.row.IdJudet!='11'" class="full-width" v-model="scope.row.Oras" size="mini"></el-input>
            <el-select v-else class="full-width" v-model="scope.row.Oras" size="mini" filterable>
              <el-option v-for="(item,idx) in Info.Sectoare" :key="'sector-'+idx" :label="item" :value="item"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="DataFactura" label="Data Factura" width="120" />
        <el-table-column prop="ValoareCuTva" label="Valoare cu TVA" width="130"  align='right'>
          <template slot-scope="scope">
            {{ scope.row.ValoareCuTva | format_money }} LEI
          </template>
        </el-table-column>
        <el-table-column prop="ValoareFaraTva" label="Valoare fara TVA" width="130"  align='right'>
          <template slot-scope="scope">
            {{ scope.row.ValoareFaraTva | format_money }} LEI
          </template>
        </el-table-column>
        <el-table-column prop="ValoareTva" label="Valoare TVA" width="120"  align='right'>
          <template slot-scope="scope">
            {{ scope.row.ValoareTva | format_money }} LEI
          </template>
        </el-table-column>
        <el-table-column prop="Rabat" label="Discount" width="200"  align='right'>
          <template slot-scope="scope">
            {{ parseFloat(scope.row.RabatFactura) * 100 }} %
          </template>
        </el-table-column>
        
        <el-table-column fixed="right" label="Actiuni" width="120" align="right">
          <template slot-scope="scope">
            
            <div v-if="scope.row.PersoanaJuridica">
              <el-tooltip content="Update judet si oras" >
                <el-button type="primary" size="mini" icon="el-icon-upload" circle @click="update_judet(scope.row)"  class="butonReg" />
              </el-tooltip>
              &nbsp;
              <el-tooltip content="Trimite ANAF" v-if="scope.row.StatusEfactura == 'none' || scope.row.StatusEfactura == 'error'">
                <el-button type="success" size="mini" icon="el-icon-upload2" circle @click="trimite_anaf(scope.row)"  class="butonReg" />
              </el-tooltip>

              <el-tooltip content="PDF E-Factura" v-if="scope.row.StatusEfactura == 'validated'">
                <el-button type="success" size="mini" icon="el-icon-tickets" circle @click="arata_pdf_efactura(scope.row)"  class="butonReg" />
              </el-tooltip>
            </div>
            <div v-else>
              Persoana fizica
            </div>

          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="refresh_info" @current-change="refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
      <customer-dialog ref="dlgCustomer" @save="refresh_info()" />
    </base-page>
  
  </template>
  
  <script>
  import settings from "@/backend/LocalSettings";
  import BasePage from "@/pages/BasePage";  
  import CustomerDialog from "@/pages/facturi-efactura/CustomerDialog.vue";
  import TitluPagina from "@/widgets/TitluPagina";

  
  export default {
    name: "facturi-efactura",
    extends: BasePage,
    components: {
      "base-page": BasePage,
      "titlu-pagina": TitluPagina,
      'customer-dialog': CustomerDialog,
    },
    data() {
      return {
        Results: [],
        TotalValoare: 0,
        base_url: "",
        
        Info: {
          Judete: [],
          Sectoare: [],
          StatusuriEfactura: []
        },
        Filters: {
            DataFactura: "",
            NumeClient: "",
            StatusEfactura: ""
        },
        intervalCheck: null,
        OrderBy: {},
        PaginationInfo: {
          Page: 1,
          PerPage: 50,
          RowCount: 0,
          PageSizes: [10, 25, 50, 100, 200],
        },
      };
    },
    methods: {
      async get_info() {
        var response = await this.post("FacturiEfactura/get_info");
        this.Info    = response.Info;
        this.refresh_info();
      },
  
      reseteaza(){
        this.Filters = {
            DataFactura: "",
            NumeClient: "",
            StatusEfactura: ""
        };
        this.refresh_info();
      },

      show_customer_dialog(id){
        this.$refs.dlgCustomer.show_me(id);
      },

      async update_judet(row){
        if (row.IdJudet=='-1') {
          this.$message({
            message: "Selectati judetul",
            type: "warning"
          });
          return;
        }
        var response = await this.post("FacturiEfactura/update_judet", {
          Id:       row.Id,
          IdJudet:  row.IdJudet,
          Oras:     row.Oras,
        });

        this.$message({
            message: "Salvat",
            type: "success"
          });
        
        this.refresh_info();
      },

      async trimite_anaf(row)
      {
        if (row.IdJudet=='-1') {
          this.$message({
            message: "Selectati judetul",
            type: "warning"
          });
          return;
        }
        var response = await this.post("FacturiEfactura/trimite_anaf", {
          Id:       row.Id,
          IdJudet:  row.IdJudet,
          Oras:     row.Oras,
        });
        
        this.refresh_info();
      },

      async trimite_bulk(){
        var response = await this.post("FacturiEfactura/trimite_bulk", {
          data: this.Filters.DataFactura
        });
        this.$message({
            message: "Am trimis " + response.NrFacturiTrimise + " facturi",
            type: "warning"
          });
        await this.refresh_info();
      },

      async interval_step()
      {
        var iduri = this.Results.map( x => x.Id );
        if (iduri.length > 0) {
          var response = await this.post("FacturiEfactura/status", { iduri: JSON.stringify(iduri) });
          var statusuri = response.Statusuri;
          statusuri.forEach( x =>{
            var item = this.Results.find( y => y.Id == x.Id );
            item.StatusEfactura = x.StatusEfactura;
            item.EroareEfactura = x.EroareEfactura;
          });
        }
      },

      arata_pdf_efactura(f){
        window.open(settings.BASE_URL + "/facturiEfactura/pdf_efactura/" + f.Id, "_blank");
      },
      async refresh_info() {
        var response = await this.post("FacturiEfactura/index", {
          Filters: this.Filters,
          OrderBy: this.OrderBy,
          PaginationInfo: this.PaginationInfo,
        });
        this.Results        = response.Results;
        this.TotalValoare   = response.TotalValoare;
        this.PaginationInfo = response.PaginationInfo;
        //
        this.select_menu_item("facturi-efactura");
      },
    },
    mounted() {
      this.base_url = settings.BASE_URL;
      this.get_info();
      this.intervalCheck = setInterval(this.interval_step, 1000 * 5);
    },
    beforeDestroy() {
      clearInterval(this.intervalCheck);
    }
  
  };
  </script>
  
  <style lang="less" scoped>
  .top50 {
    margin-top: 20px;
  }
  @media only screen and (min-width: 768px) {
    .filtre {
      display: flex;
      grid-template-columns: none;
    }
  
    .el-table tr {
      word-break: break-word;
      // font-size: 12px !important;
    }
  
    .footer-form {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
  }
  
  .filtre {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(2);
  
    .el-col {
      width: 31%;
      margin-right: 2%;
    }
  
    .el-date-editor {
      width: 100%;
    }
    
    
    // grid-auto-rows: 100px;
    .el-input-number {
      width: 100% !important;
    }
  }
  </style>
  