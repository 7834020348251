<template>
    <el-dialog title="Furnizori" :visible.sync="showPopup" class="my-dialog-class" append-to-body >
      <el-form
        label-position="top"
        :inline="false"
        :rules="rules"
        label-width="100%"
        :model="selectedObject"
        ref="my-form"
        @submit.prevent="save"
        v-loading="loadingVisible"
      >
        <el-row :gutter="15">
          <el-col :md="12">
            <el-form-item prop="Nume" label="Nume">
              <el-input class="full-width" v-model="selectedObject.Nume" />
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="CodFiscal" label="Cod fiscal">
                <el-input class="full-width" v-model="selectedObject.CodFiscal" />
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="Address" label="Adresa">
                <el-input class="full-width" v-model="selectedObject.Address" />
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item prop="RegCom" label="Reg Com">
                <el-input class="full-width" v-model="selectedObject.RegCom" />
            </el-form-item>
          </el-col>
          
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPopup = false"> Renunta </el-button>
        <el-button type="primary" @click="save"> Salveaza </el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  import settings from "@/backend/LocalSettings";
  import BasePage from "@/pages/BasePage";
  
  export default {
    name: "Customer_dialog",
    extends: BasePage,
    components: {},
    data() {
      return {
        baseUrl: "",
        showPopup: false,
        selectedObject: {
            Id: "",
            Nume: "",
            CodFiscal: "",
            Address:"",
            RegCom:"",
        },
        Info: {},
        rules: {
          Nume: [{ required: true, message: "Campul este obligatoriu" }],
          Address: [{ required: true, message: "Campul este obligatoriu" }],
        },
      };
    },
    methods: {
      show_me: async function (id) {
        var result = await this.post("FacturiEfactura/get_info_item_dialog_customer", {
            id: id,
        });
        this.selectedObject = result.Item;
        this.showPopup      = true;
      },
  
      
  
      save: async function () {
        this.$refs["my-form"].validate(async (valid) => {
          if (valid) 
          {
            var result = await this.post("FacturiEfactura/save_customer", {
              object: this.selectedObject,
            });
            this.showPopup = false;
            this.$emit("save", result.obj);
          }
        });
      },
    },
    mounted: function () {
      this.baseUrl = settings.BASE_URL;
    },
  };
  </script>
  
  <style lang="less" scoped>
  .full-width {
    width: 100% !important;
    .el-input {
      width: 85% !important;
    }
    .el-select {
      width: 85% !important;
    }
  
    .btn-a {
      margin: 0;
      padding: 0;
      width: 15% !important;
      .el-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  </style>